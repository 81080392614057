import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  public userData;
  public httpOptions;
  public editUser = false;
  public editDetails;
  public deleteMealId;
  public editMeal = false;
  public editMealDetails;
  public menuDelete = false;
  public menuDeleteId;
  public menuEditDetails;
  public editItem = false;
  public editItemDetails;
  public editPromoCode = false;
  public editPromoCodeDetails;
  public deleteUserId;
  public meallisting = [];
  public menuListing = [];
  public containers = [];
  public userListing = [];
  public deleteItemId;
  public deletePromoCodeId;
  public itemListing = [];
  public promoCodeListing = [];
  public currentSelection = '';
  public delCuisineId = '';
  public delSubCatId = '';
  public delCuisine = false;
  public delSubCat = false;
  public cuisineListing = [];
  public subCatListing = [];
  public subCatId = ''
  public subCatName = ''
  public accessPermission = []
  public importMealStatus = 0


  constructor(private http: HttpClient) { }

  // get user data
  public getUserData(): void {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    return this.userData;
  }

  // create user API
  createUser(data, token): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/restUsers?api_token=' + token, data);
  }

  // update user API
  updateUser(data, id, token) {
    return this.http.patch(environment.APIconfigURL + '/manager/restUsers/' + id + '?api_token=' + token, data);
  }

  // get user list
  userList(token) {
    return this.http.get(environment.APIconfigURL + '/manager/restUsers?api_token=' + token);
  }

  // delete user
  deleteUser(data, id, token) {
    return this.http.delete(environment.APIconfigURL + '/manager/restUsers/' + id + '?api_token=' + token, data);
  }

  // get subcategory
  getSubcat(token) {
    return this.http.get(environment.APIconfigURL + '/manager/subCategory?api_token=' + token);
  }

  // get flag list
  getFlags(token) {
    return this.http.get(environment.APIconfigURL + '/manager/specialFlag?api_token=' + token);
  }

  // get food list
  getFoodList(token) {
    return this.http.get(environment.APIconfigURL + '/manager/foods?api_token=' + token);
  }
  // get extras list
  getExtrasList(token) {
    return this.http.get(environment.APIconfigURL + '/manager/extras?api_token=' + token);
  }

  // create list
  createMeal(token, data) {
    return this.http.post(environment.APIconfigURL + '/manager/storeMeal?api_token=' + token, data);
  }

  // meal list
  getMealList(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/mealList?api_token=' + token);
  }

  // update meal
  updateMeal(token, id, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/mealUpdate/' + id + '?api_token=' + token, data);
  }

  // delete meal
  deleteMeal(token, id): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/delete/' + id + '?api_token=' + token);
  }

  // menu listing
  getMenuList(token): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Headers', 'Content-Type',)
    headers.append('Content-Type', 'application/json');
headers.append('platform', 'WEB');
headers.append('app-version', '1.00');
headers.append('version', '1.0');
headers.append('accept', 'application/json');
headers.append('Access-Control-Allow-Origin', '*');
headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
headers.append('Access-Control-Allow-Origin', '*');
headers.set('Access-Control-Allow-Origin', '*');
      return this.http.get(environment.APIconfigURL + '/manager/menus?api_token=' + token, 
      {                                                                                                                                                                                 
        headers: headers, 
      });
  }

  // menu create
  createMenu(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/menus?api_token=' + token, data);
  }

  // delete menu
  deleteMenu(id, token) {
    return this.http.delete(environment.APIconfigURL + '/manager/menus/' + id + '?api_token=' + token);
  }

  // updateMenu
  updateMenu(token, id, data): Observable<any> {
    return this.http.patch(environment.APIconfigURL + '/manager/menus/' + id + '?api_token=' + token, data);
  }

  getAddItemList(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/items?api_token=' + token);
  }

  getAddMealList(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/meals?api_token=' + token);
  }

  resetPassword(id, token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/reset/' + id + '?api_token=' + token);
  }

  getItemList(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/rest_items?api_token=' + token);
  }

  getItemByID(token, id:number): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/rest_items/'+id+'?api_token=' + token);
  }

  createItem(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/rest_items?api_token=' + token, data);
  }

  updateItem(token, id, data) {
    return this.http.post(environment.APIconfigURL + '/manager/update_items/' + id + '?api_token=' + token, data);
  }

  deleteItem(id, token) {
    return this.http.delete(environment.APIconfigURL + '/manager/rest_delete/' + id + '?api_token=' + token);
  }

  getPromoCodeList(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/promo-code?api_token=' + token);
  }

  getPromoCode(token, id): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/promo-code/' + id + '?api_token=' + token);
  }

  createPromoCode(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/promo-code?api_token=' + token, data);
  }

  updatePromoCode(token, id, data) {
    return this.http.put(environment.APIconfigURL + '/manager/promo-code/' + id + '?api_token=' + token, data);
  }

  deletePromoCode(token, id) {
    return this.http.delete(environment.APIconfigURL + '/manager/promo-code/' + id + '?api_token=' + token);
  }

  addIngredient(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/add_ingredient?api_token=' + token, data);
  }

  createRecipe(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/recipe_store?api_token=' + token, data);
  }

  getRecipeList(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/recipes?api_token=' + token);
  }

  getOrders(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/restaurant_orders?api_token=' + token);
  }

  deleteAll(token, data:number[]): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/bulk-order-delete?api_token=' + token, {id: data});
  }


  getStatus(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/statuses?api_token=' + token);
  }

  updateOrder(token, id, data): Observable<any> {
    return this.http.patch(environment.APIconfigURL + '/manager/restaurant_orders/' + id + '?api_token=' + token, data);
  }

  getCuisineListing(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/cuisine_list?api_token=' + token);
  }

  createSubcat(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/category_store?api_token=' + token, data);
  }

  createCuisine(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/cuisine_store?api_token=' + token, data);
  }

  updateCuisine(token, id, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/cuisine_update/' + id + '?api_token=' + token, data);
  }

  deleteSubcate(id, token) {
    return this.http.get(environment.APIconfigURL + '/manager/category_delete/' + id + '?api_token=' + token);
  }

  deleteCuisine(id, token) {
    return this.http.get(environment.APIconfigURL + '/manager/cuisine_delete/' + id + '?api_token=' + token);
  }

  dashboardGraph(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/dashboard?api_token=' + token, data);
  }

  searchDashboard(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/order/search?api_token=' + token, data);
  }

  mealsDashboard(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/order/dash_order?api_token=' + token, data);
  }

  importDefaultMenu(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/import_menu?api_token=' + token);
  }

  getRestaurantDetails(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/webRestaurants?api_token=' + token);
  }

  updateRestaurantDetails(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/webRestaurants?api_token=' + token, data);
  }

  getUserProfile(token): Observable<any> {
    return this.http.get(environment.APIconfigURL + '/manager/get_profile?api_token=' + token);
  }

  updateUserProfile(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/update_profile?api_token=' + token, data);
  }

  updateItemPrice(token, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + '/manager/recipe_update?api_token=' + token, data);
  }

  updateCatData(token, catId, data): Observable<any> {
    return this.http.post(environment.APIconfigURL + `/manager/category_update/${catId}?api_token=` + token, data);
  }

  checkImportMeal (token) : Observable<any>{
    return this.http.get(environment.APIconfigURL + '/manager/check_import?api_token=' + token);
  }


}

<app-header></app-header>
<div class="body-wrapper" [ngClass]="{'mobile-open' : mobileToggler}">
    <div class="left">
        <app-side-bar></app-side-bar>
    </div>
    <div class="right">
        <div class="right-content">
            <router-outlet></router-outlet>
        </div>
    </div>    
</div>
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, AfterViewInit {
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  public regDetails: any;
  public mobileNumber;
  public otp: string;
  public errorMsg = '';
  public config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    containerClass: 'otpInput-wrapper',
    inputStyles: {
      width: '50px',
      height: '50px'
    }
  };

  constructor(public loginService: LoginService, public router: Router) { }

  ngOnInit(): void {
    this.regDetails = JSON.parse(localStorage.getItem('registerDetails'));
    if (this.regDetails) {
      this.mobileNumber = this.regDetails.country_code + this.regDetails.mobile_number;
    } else {
      this.mobileNumber = '+336 60 60 60 60';
    }
  }

  ngAfterViewInit() {
    // this.setOtp(this.loginService.otp);
  }

  setOtp(value) {
    this.ngOtpInput.setValue(value);
  }

  resendOtp() {
    const details = {
      country_code: this.regDetails.country_code,
      mobile_number: this.regDetails.mobile_number,
      email: this.regDetails.email
    };
    this.loginService.sendOtp(details).subscribe(res => {
      if (res.response.code === 200) {
        this.loginService.otp = res.response.otp;
        this.setOtp(this.loginService.otp);
      } else {
        this.errorMsg = res.response.message;
      }
    });
  }

  onOtpChange(otp) {
    this.otp = otp;
  }

  submit(value): void {
    const data = {
      mobile_number: this.regDetails.mobile_number,
      country_code: this.regDetails.country_code,
      // otp: this.otp ? this.otp : (this.loginService.otp).toString().replace(/[^\d]/g, ''),
      otp: this.otp,

      email: this.regDetails.email
    };
    this.loginService.verifyOtp(data).subscribe(res => {
      const vCode = res.response.v_code;
      if (res.response.code === 200) {
        const details = {
          outlet_name: this.regDetails.outlet_name,
          name: this.regDetails.name,
          manager_id: this.regDetails.manager_id,
          registration_number: this.regDetails.registration_number,
          country: this.regDetails.country,
          address: this.regDetails.address,
          country_code: this.regDetails.country_code,
          mobile_number: this.regDetails.mobile_number.replace(/[^\d]/g, ''),
          email: this.regDetails.email,
          password: this.regDetails.password,
          v_code: vCode,
          currency: this.regDetails.currency
        };
        this.loginService.register(details).subscribe(response => {
          if (response.response.code === 200) {
            this.router.navigate(['/auth/contact']);
            const userData = {
              email: this.regDetails.email,
              password: this.regDetails.password,
            };
            localStorage.setItem('userData', JSON.stringify(userData));
          }
        });
      } else {
        this.errorMsg = res.response.message;
      }
    });
  }
}

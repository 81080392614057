import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
import { RestaurantMainComponent } from './restaurant-main.component';

const appRoutes: Routes = [
  {
    path: '',
    component: RestaurantMainComponent,
    children: [
      { path: '', redirectTo: 'restaurant', pathMatch: 'full' },
      {
        path: 'restaurant',
        canActivate: [AuthGuard],
        loadChildren: () => import('./restaurant/restaurant.module').then((m) => m.RestaurantModule),
      },
      {
        path: 'meals',
        canActivate: [AuthGuard],
        loadChildren: () => import('./meals/meals.module').then((m) => m.MealsModule),
      },
      {
        path: 'menu',
        canActivate: [AuthGuard],
        loadChildren: () => import('./menu/menu.module').then((m) => m.MenuModule),
      },
      {
        path: 'users',
        canActivate: [AuthGuard],
        loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'items',
        canActivate: [AuthGuard],
        loadChildren: () => import('./items/items.module').then((m) => m.ItemsModule),
      },
      {
        path: 'orders',
        canActivate: [AuthGuard],
        loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
      },
      {
        path: 'promo-code',
        canActivate: [AuthGuard],
        loadChildren: () => import('./promo-code/promo-code.module').then((m) => m.PromoCodeModule),
      },
      {
        path: '**',
        canActivate: [AuthGuard],
        redirectTo: '/restaurant',
        pathMatch: 'full',
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class RestaurantRoutingModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    public userData: any;
    public loginData: any;
    public otp: number;
    public httpOptions;
    public firstLogin = 0;
    public token;
    public userName = '';
    constructor(
        private http: HttpClient
    ) { }

    // SignIn API
    signIn(data): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/manager/login', data);
    }

    // Send OTP
    sendOtp(data): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/manager/sendOtp', data);
    }

    // Verify OTP
    verifyOtp(data): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/manager/verifyOtp', data);
    }

    // register user
    register(data): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/manager/register', data);
    }

    // signout
    signout(apiToken): Observable<any> {
        return this.http.get(environment.APIconfigURL + '/logout?api_token=' + apiToken);
    }

    // forget password
    forgetPassword(data): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/send_reset_link_forget', data);
    }

    // reset password
    resetPassword(data): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/verify_forget', data);
    }

    firstTimeRest(data, token): Observable<any> {
        return this.http.post(environment.APIconfigURL + '/reset_password?api_token=' + token, data);
    }
    // currency data dropdown menu
  dropdownCurrency() {
    return this.http.get(`https://spoonit-api.com/api/currencies?api_token=NL3lSwQQLv60KLtwpTSYnNtJdj47ftgdeWxHXSF5LVu28IFwr0D0woeLx4sU`);
  }
}

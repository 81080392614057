<section class="adminAuth-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class='verification'>
                    <div class='reset-password resetpassInner'>
                        <h3 >Reset password</h3>
                        <div class='subheadings' style="margin-top: 28px; ">
                            <h4>Choose your new password</h4>
                        </div>
                        <div class="form-group">
                            <span><img src='assets/images/reset-icon.png'></span>
                        </div>
                        <form name="resetPass" #resetPass="ngForm" autocomplete="off">
                        <div class="form-group">
                            <div class="wrapIcon">
                                <input type='password' name='password' #password="ngModel" [(ngModel)]="loginData.password"  class='form-control' placeholder="New password">
                            </div>
                            <div class="errorClass">
                                <span>{{errorMsgPass}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="wrapIcon">
                                <input type='password' name='confirmPassword' #confirmPassword="ngModel" [(ngModel)]="loginData.confirmPassword" class='form-control' placeholder="Confirm password">
                            </div>
                            <div class="errorClass">
                                <span>{{errorMsgConfirmPass}}</span>
                                <span>{{errorMsgGlobal}}</span>
                            </div>
                        </div>
                        </form>
                        <div class="form-group authButton-wrapper">
                            <button class='btn btn-lg' (click)='resetpass(resetPass)'>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
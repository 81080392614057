<section class="adminAuth-wrapper signUp-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="authInner signUp-Inner">
                    <h3>First connection</h3>
                    <form name="signupForm" [formGroup]="signupForm" autocomplete="false" class='signup-form'>
                        <div class='form-group' [ngClass]="{'active': signupForm.get('outlet')?.value}">
                            <input type='text' formControlName='outlet' #outlet class='form-control' (keydown)="validationCheck($event, 'outlet')" required>
                            <label>Outlet name <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.outlet'>
                                <span>{{formErrroMsg?.outlet}}</span>
                            </div>
                        </div>
                        <div class='form-group' [ngClass]="{'active': signupForm.get('manager')?.value}">
                            <input type='text' formControlName='manager' class='form-control' (keydown)="validationCheck($event, 'manager')" required>
                            <label>Manager name <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.manager'>
                                <span>{{formErrroMsg?.manager}}</span>
                            </div>
                        </div>

                        <div class='form-group' [ngClass]="{'active': signupForm.get('id')?.value}">
                            <input type='text' formControlName='id' class='form-control' (keydown)="validationCheck($event, 'managerId')"  required>
                            <label>Manager I.D <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.id'>
                                <span>{{formErrroMsg?.id}}</span>
                            </div>
                            <div class="errorClass" *ngIf="signupForm.value.id && signupForm.controls['id'].errors">
                                <span >Manager ID can be alpha numeric only</span>
                            </div>
                        </div>

                        <div class='form-group' [ngClass]="{'active': signupForm.get('regNumber')?.value}">
                            <input type='text' formControlName='regNumber' class='form-control' (keydown)="validationCheck($event, 'regId')" required>
                            <label>Business registration number <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.regNumber'>
                                <span>{{formErrroMsg?.regNumber}}</span>
                            </div>
                            <div class="errorClass" *ngIf="signupForm.value.regNumber && signupForm.controls['regNumber'].errors">
                                <span >Business registration number can be alpha numeric only</span>
                            </div>
                        </div>

                        <div class='form-group' [ngClass]="{'active': signupForm.get('country')?.value}">
                            <input type='text' formControlName='country' (keydown)="validationCheck($event, 'country')" class='form-control' required>
                            <label>Country <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.country'>
                                <span>{{formErrroMsg?.country}}</span>
                            </div>
                        </div>
                        <div class='form-group' [ngClass]="{'active': signupForm.get('address')?.value}">
                            <input type='text' formControlName='address' class='form-control'>
                            <label>Address <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.address'>
                                <span>{{formErrroMsg?.address}}</span>
                            </div>
                        </div>
                        <div class='form-group phone-wrapper' [ngClass]="{'active': signupForm.get('phone')?.value}">
                            <ngx-intl-tel-input [inputId]="'adsasd'" style='margin-left: 10px;' [cssClass]="'form-control'" [enableAutoCountrySelect]="false" [enablePlaceholder]="false" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" placeholder='9999999999' formControlName="phone" class="telInput">
                            </ngx-intl-tel-input>
                            <label>Phone number <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.phone'>
                                <span>{{formErrroMsg?.phone}}</span>
                            </div>
                            <div *ngIf="signupForm.value.phone && signupForm.controls['phone'].errors" class="errorClass">
                                <span>Invalid phone number</span>
                            </div>
                        </div>
                        <div class='form-group' [ngClass]="{'active': signupForm.get('currency')?.value}">
                            <select class="form-control" 
                                 formControlName='currency'>
                                <option value='' selected disabled>Select Currency</option>
                                <option value={{cur.id}} 
                                    *ngFor='let cur of currencyList'>{{cur?.name | titlecase}}</option>
                            </select>
                            <div class="errorClass" *ngIf='formErrroMsg?.currency'>
                                <span class='error'>{{formErrroMsg?.currency}}</span>
                            </div>
                        </div>
                        <div class='form-group' [ngClass]="{'active': signupForm.get('email')?.value}">
                            <input type='email' formControlName='email' class='form-control'>
                            <label>Email <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.email'>
                                <span>{{formErrroMsg?.email}}</span>
                            </div>
                            <div *ngIf="signupForm.value.email && signupForm.controls['email'].errors"
                                class="errorClass">
                               <span>Email must be a valid email address</span></div>
                        </div>
                        <div class="form-group" [ngClass]="{'active': pasword}">
                            <input type='password' name='password' #password class='form-control' [(ngModel)]="pasword" [ngModelOptions]="{standalone: true}">
                            <label>Password <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.password'>
                                <span>{{formErrroMsg?.password}}</span>
                            </div>
                        </div>
                        <div class="form-group" [ngClass]="{'active': confirmPass}">
                            <input type='password' name='password' #confirmPassword class='form-control' [(ngModel)]="confirmPass" [ngModelOptions]="{standalone: true}">
                            <label>Confirm Password <span class="red">*</span></label>
                            <div class="errorClass" *ngIf='formErrroMsg?.confirmPass'>
                                <span>{{formErrroMsg?.confirmPass}}</span>
                            </div>
                        </div>
                        <div *ngIf='formErrroMsg?.passMatch' class="errorClass">
                            <span>{{formErrroMsg?.passMatch}}</span>
                        </div>
                        <div *ngIf='errorMsg' class="errorClass">
                            <span>{{errorMsg}}</span>
                        </div>
                        <div class="form-group authButton-wrapper">
                            <button class="btn btn-lg" (click)='submit(signupForm)'>Sign up</button>
                        </div>
                    </form>
                    <div class="authFooter">
                        <p>By signing up you agree to out Terms of Use and Privacy Policy</p>
                        <p class="mt-1">Already have an Account? <a [routerLink]="'/auth/login'">Sign In</a> </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public signupForm: FormGroup;
  separateDialCode = true;
  errorMsg: string;
  formErrroMsg = {
    outlet: '', manager: '', id: '', regNumber: '', country: '', address: '',
    phone: '',currency: '', email: '', password: '', confirmPass: '', passMatch: ''
  };
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required])
  });
  @ViewChild('password') public password;
  @ViewChild('confirmPassword') public confirmPassword;
  public loginData = { password: '' };
  public pasword;
  public confirmPass;
  public currencyList;
  public currencyVal;

  constructor(private formBuilder: FormBuilder, private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {
    this.loginService.dropdownCurrency().subscribe(response => {
      this.currencyList = response
      this.currencyList = this.currencyList.response.data
      
    }, (err) => {
      
    });
    
    
    this.signupForm = this.formBuilder.group({
      outlet: ['', [Validators.required]],
      manager: ['', [Validators.required]],
      id: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]],
      regNumber: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]],
      country: ['', [Validators.required]],
      address: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      currency: ['',[Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }

  

  submit(signupForm): void {

    this.formErrroMsg = {
      outlet: '', manager: '', id: '', regNumber: '', country: '', address: '',
      phone: '',currency:'', email: '', password: '', confirmPass: '', passMatch: ''
    };
    if (!signupForm.valid || this.password.nativeElement.value === '' || this.confirmPassword.nativeElement.value === '') {
      if (signupForm.value.outlet === '') {
        this.formErrroMsg.outlet = 'Please enter the outlet name';
      }
      if (signupForm.value.manager === '') {
        this.formErrroMsg.manager = 'Please enter the manager name';
      }
      if (signupForm.value.id === '') {
        this.formErrroMsg.id = 'Please enter the manager id';
      }
      if (signupForm.value.regNumber === '') {
        this.formErrroMsg.regNumber = 'Please enter the registration number';
      }
      if (signupForm.value.country === '') {
        this.formErrroMsg.country = 'Please enter the country';
      }
      if (signupForm.value.address === '') {
        this.formErrroMsg.address = 'Please enter the address';
      }
      if (!signupForm.value.phone) {
        this.formErrroMsg.phone = 'Please enter the mobile number';
      }
      if (signupForm.value.email === '') {
        this.formErrroMsg.email = 'Please enter the email';
      }
      if (signupForm.value.currency === '') {
        this.formErrroMsg.currency = 'Please select the currency';
      }
      if (this.password.nativeElement.value === '') {
        this.formErrroMsg.password = 'Please enter the password';
      }
      if (this.password.nativeElement.value !== '' && this.password.nativeElement.value.length <= 4) {
        this.formErrroMsg.password = 'Password should be minimum 5 characters';
      }
      if (this.confirmPassword.nativeElement.value === '') {
        this.formErrroMsg.confirmPass = 'Please enter the confirm password';
      }
      if (this.confirmPassword.nativeElement.value !== '' && this.confirmPassword.nativeElement.value.length <= 4) {
        this.formErrroMsg.confirmPass = 'Password should be minimum 5 characters';
      }
      return;
    }
    if (this.password.nativeElement.value !== '' || this.confirmPassword.nativeElement.value !== '') {
      if (this.password.nativeElement.value !== '' && this.password.nativeElement.value.length <= 4) {
        this.formErrroMsg.password = 'Password should be minimum 5 characters';
      }
      if (this.confirmPassword.nativeElement.value !== '' && this.confirmPassword.nativeElement.value.length <= 4) {
        this.formErrroMsg.confirmPass = 'Password should be minimum 5 characters';
      }
      if (this.password.nativeElement.value !== this.confirmPassword.nativeElement.value) {
        this.formErrroMsg.passMatch = 'Password and confirm password should be same';
        return;
      }
    }
    if (this.password.nativeElement.value !== this.confirmPassword.nativeElement.value) {
      this.formErrroMsg.passMatch = 'Password and confirm password should be same';
      return;
    }
    if (this.formErrroMsg.password !== '' || this.formErrroMsg.confirmPass !== '') {
      return;
    }
    const data = {
      outlet_name: signupForm.value.outlet,
      name: signupForm.value.manager,
      manager_id: signupForm.value.id,
      registration_number: signupForm.value.regNumber,
      country: signupForm.value.country,
      address: signupForm.value.address,
      country_code: signupForm.value.phone.dialCode,
      mobile_number: signupForm.value.phone.number.replace(/[^\d]/g, ''),
      currency: signupForm.value.currency,
      email: signupForm.value.email,
      password: this.password.nativeElement.value
    };
    this.loginService.loginData = data;
    localStorage.setItem('registerDetails', JSON.stringify(this.loginService.loginData));
    const details = {
      country_code: signupForm.value.phone.dialCode,
      mobile_number: signupForm.value.phone.number.replace(/[^\d]/g, ''),
      email: signupForm.value.email
    };
    this.loginService.sendOtp(details).subscribe(res => {
      if (res.response.code === 200) {
        this.loginService.otp = res.response.otp;
        this.router.navigate(['/auth/verification']);
      } else if (res.response.message === 'This mobile number is already in use') {
        this.formErrroMsg.phone = res.response.message;
      } else if (res.response.message[0] === 'The email has already been taken.') {
        this.formErrroMsg.email = res.response.message[0];
      } else if (res.response.message === 'This mobile number is blocked due to maximum attempt') {
        this.formErrroMsg.phone = res.response.message;
      } else {
        this.errorMsg = res.response.message;
      }
    });
  }

  validationCheck(event, type) {
    const charCode = event.keyCode;
    
    if (type === 'country' || type === 'manager') {
      if ((charCode > 64 && charCode < 91) || charCode === 8 || charCode === 32 || charCode === 9) {
        return true;
      } else {
        return false;
      }
    } 
  }

}

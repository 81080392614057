import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalSharedService {

  public sideMenuObserver: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  isMobileMenuActive(data) {
    this.sideMenuObserver.next(data);
  }

}

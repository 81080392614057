import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalDataService } from '../../shared/service/data.service';

@Injectable()
export class LoginGuard implements CanActivate {
  userLogin = false;
  userData;
  constructor(private router: Router, private globalDataService: GlobalDataService) {
  }

  canActivate(): boolean {
    this.userData = this.globalDataService.getUserData() ;
    if (this.userData && this.userData.api_token) {
      this.router.navigate(['/restaurant']);
      return false;
    } else {
      return true;
    }
  }
}

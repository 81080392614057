<section class="adminAuth-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="authInner loginWrapper">
                    <form name="loginForm" #loginForm="ngForm" autocomplete="off">
                        <div class="form-group">
                            <div class="wrapIcon">
                                <span><i class="fas fa-user" aria-hidden="true"></i></span>
                                <input type='email' [(ngModel)]="loginData.email" name='email' #email="ngModel" required class='form-control' placeholder="Email">
                            </div>
                            <div class="errorClass">
                                <span>{{errorMsg}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="wrapIcon">
                                <span><i class="fas fa-lock" aria-hidden="true"></i></span>
                                <input type='password' name='password' #password="ngModel" [(ngModel)]="loginData.password" class='form-control' placeholder="Password">
                            </div>
                            <div class="errorClass">
                                <span>{{errorMsgPass}}</span>
                                <span>{{errorMsgGlobal}}</span>
                            </div>
                        </div>
                        <div class="form-group frgtpwd">
                            <a  [routerLink]="'/auth/forget-password'">Forgot your password?</a>
                        </div>
                        <div class="form-group authButton-wrapper">
                            <button class="btn btn-lg" (click)="login(loginForm, 'email')">Login</button>
                        </div>
                    </form>
                    <div class="login-suppport">
                        <p>Or Sign Up</p>
                    </div>
                    <div class="authFooter">
                        <p>Don't have an account? <a [routerLink]="'/auth/register'">Sign up</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
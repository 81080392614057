import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'app-forget-passsword',
  templateUrl: './forget-passsword.component.html',
  styleUrls: ['./forget-passsword.component.scss']
})
export class ForgetPassswordComponent implements OnInit {

  public loginData = { email: '' };
  public errorMsgEmail = '';
  public errorMsgGlobal = '';

  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {
  }

  sendEmail(values): void {
    this.errorMsgGlobal = '';
    this.errorMsgEmail = '';
    if (values.value.email === '') {
      this.errorMsgEmail = 'Please enter the email';
      return;
    } else {
      this.errorMsgEmail = '';
    }
    const pattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w #.-]*/?';
    const result = values.value.email.match(pattern);
    if (!result) {
      this.errorMsgEmail = 'Please enter a valid email address';
      return;
    } else {
      this.errorMsgEmail = '';
    }
    if (this.errorMsgEmail !== '') {
      return;
    }

    let data;
    data = {
      email: values.value.email
    };

    this.loginService.forgetPassword(data).subscribe(response => {
      if (response.response.code === 200) {
        Swal.fire(response.response.message, '', 'success');
        this.errorMsgGlobal = '';
        this.errorMsgEmail = '';
      } else {
        Swal.fire(response.response.message, '', 'error');
      }
    }, (err) => {
      Swal.fire(err.error.message, '', 'error');
    });
  }

}

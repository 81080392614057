import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { VerificationComponent } from './verification/verification.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoginService } from '../service/login.service';
import { LoginGuard } from '../shared/guards/login-guard';
import { AuthMainComponent } from './auth-main.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgetPassswordComponent } from './forget-passsword/forget-passsword.component';
import { ContactPageComponent } from './contact-page/contact-page.component';

const appRoutes: Routes = [
  {
    path: '',
    component: AuthMainComponent,
    children: [
      { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
      { path: 'auth/login', component: LoginComponent, canActivate: [LoginGuard] },
      { path: 'auth/register', component: RegisterComponent, canActivate: [LoginGuard] },
      { path: 'auth/reset-password', component: ResetPasswordComponent, canActivate: [LoginGuard] },
      { path: 'auth/forget-password', component: ForgetPassswordComponent, canActivate: [LoginGuard] },
      { path: 'auth/verification', component: VerificationComponent, canActivate: [LoginGuard] },
      { path: 'auth/contact', component: ContactPageComponent, canActivate: [LoginGuard] }
    ]
  }
];

@NgModule({
  declarations: [LoginComponent, RegisterComponent, VerificationComponent, AuthMainComponent,
    ResetPasswordComponent, ForgetPassswordComponent, ContactPageComponent],
  imports: [RouterModule.forChild(appRoutes),
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxIntlTelInputModule,
    NgOtpInputModule],
  exports: [RouterModule],
  providers: [LoginService]
})
export class AuthModule { }

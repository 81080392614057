import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalDataService } from '../../shared/service/data.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  public showResurant = true
  public saveResturant = true
  public showPromoCode = true
  public savePromoCode = true
  public showMenu = true
  public saveMenu = true
  public showUser = true
  public addUser = true
  public updateUser = true
  public showMeals = true
  public createMeal = true
  public showItem = true
  public addItem = true
  public orderList = true
  public dashBoardGraph = true
  public dashBoardSearch = true
  public dashBoardSellerList = true
  public settings = true
  public level;
  public accessLevel : any = []
  constructor(private router: Router, public dataService: GlobalDataService) { }

  ngOnInit(): void {
    const url = this.router.url;
    this.dataService.currentSelection = '/restaurant';
    this.level = JSON.parse(localStorage.getItem('userData')).roles[0].name;
    this.accessLevel = JSON.parse(localStorage.getItem('userData')).permission;
    this.dataService.currentSelection = url;
    if (url && url.includes('/users/create-user')) {
      this.dataService.currentSelection = '/users/create-user';
    }

    // checl access level
      this.showMenu = this.accessLevel.includes('menus.index')
      this.saveMenu = this.accessLevel.includes('menus.store')
      this.showResurant = this.accessLevel.includes('webRestaurants.index')
      this.saveResturant = this.accessLevel.includes('webRestaurants.store')
      this.showPromoCode = this.accessLevel.includes('webPromoCode.index')
      this.savePromoCode = this.accessLevel.includes('webPromoCode.store')
      this.showUser = this.accessLevel.includes('restUsers.index')
      this.updateUser = this.accessLevel.includes('restUsers.update')
      this.addUser = this.accessLevel.includes('restUsers.store')
      this.showMeals = this.accessLevel.includes('meal.list')
      this.createMeal = this.accessLevel.includes('meal.store')
      this.showItem = this.accessLevel.includes('rest_items.index')
      this.addItem = this.accessLevel.includes('rest_items.store')
      this.orderList = this.accessLevel.includes('restaurant_orders.index')
      this.dashBoardGraph = this.accessLevel.includes('dashboard.graph')
      this.dashBoardSearch = this.accessLevel.includes('dashboard.search')
      this.dashBoardSellerList = this.accessLevel.includes('dashboard.seller_list')
      this.settings = this.accessLevel.includes('settings')

    //
  }

  public changeSection(sectionName) {

    if( sectionName != '/orders'){
      let interValId : any = localStorage.getItem('intervalId')
      interValId = Number(interValId)
      clearInterval(interValId);
    }

    this.dataService.currentSelection = sectionName;
    if (sectionName === '/users/create-user') {
      this.dataService.editUser = false;
    }
    if (sectionName === '/meals/create-meal') {
      this.dataService.editMeal = false;
      this.dataService.editMealDetails = [];
    }
    if (sectionName === '/items/create-item') {
      this.dataService.editItem = false;
      this.dataService.editItemDetails = '';
    }
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSharedService } from '../shared/service/shared.service';

@Component({
  selector: 'app-restaurant-main',
  templateUrl: './restaurant-main.component.html',
  styleUrls: ['./restaurant-main.component.scss']
})
export class RestaurantMainComponent implements OnInit {
  public mobileToggler = false;

  constructor(private router: Router, public sharedService: GlobalSharedService) {
    this.sharedService.sideMenuObserver.subscribe(
      data => {
        this.mobileToggler = data;
      }
    );
  }

  ngOnInit(): void {
  }

}

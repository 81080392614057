import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from '../../service/login.service';
import { GlobalDataService } from '../../shared/service/data.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public loginData = { password: '', confirmPassword: '' };
  public errorMsgPass = '';
  public errorMsgConfirmPass = '';
  public errorMsgGlobal = '';
  public token;
  public params;
  public authToken;
  public userData;

  constructor(
    private router: Router,
    private loginService: LoginService,
    public activatedRoute: ActivatedRoute,
    public globalDataService: GlobalDataService) { }

  ngOnInit(): void {
    this.userData = this.globalDataService.getUserData();
    if (this.userData && this.userData.api_token) {
      this.token = JSON.parse(localStorage.getItem('userData')).api_token;
    }
    this.activatedRoute.queryParams.subscribe((params) => {
      this.params = params;
    });
    if (this.params && this.params.auth_token) {
      this.authToken = this.params.auth_token;
    }
  }

  resetpass(values): void {
    this.errorMsgGlobal = '';
    this.errorMsgPass = '';
    if (values.value.password === '') {
      this.errorMsgPass = 'Please enter the password';
    } else {
      this.errorMsgPass = '';
    }

    if (values.value.confirmPassword === '') {
      this.errorMsgConfirmPass = 'Please enter the confirm password';
    } else {
      this.errorMsgConfirmPass = '';
    }

    if (this.errorMsgConfirmPass !== '' || this.errorMsgPass !== '') {
      return;
    }

    if (values.value.password !== values.value.confirmPassword) {
      this.errorMsgGlobal = 'Password and confirm password should be same';
      return;
    } else {
      this.errorMsgGlobal = '';
    }

    if (values.value.password !== '' && values.value.password.length <= 4) {
      this.errorMsgGlobal = 'Password should be minimum 5 characters';
      return;
    } else {
      this.errorMsgGlobal = '';
    }

    let data;
    if (this.loginService.firstLogin === 1) {
      data = {
        password: values.value.password,
        first: 0
      };
      this.loginService.firstTimeRest(data, this.loginService.token ).subscribe(response => {
        if (response.response.code === 200) {
          Swal.fire(response.response.message, '', 'success');
          this.router.navigate(['/auth/login']);
          this.errorMsgGlobal = '';
          this.errorMsgPass = '';
          this.errorMsgConfirmPass = '';
          this.loginService.firstLogin = 0;
        } else {
          Swal.fire(response.response.message, '', 'error');
        }
      }, (err) => {
        Swal.fire(err.error.message, '', 'error');
      });
    } else {
      data = {
        password: values.value.password,
        token: this.authToken,
      };
      this.loginService.resetPassword(data).subscribe(response => {
        if (response.response.code === 200) {
          Swal.fire(response.response.message, '', 'success');
          this.router.navigate(['/auth/login']);
          this.errorMsgGlobal = '';
          this.errorMsgPass = '';
          this.errorMsgConfirmPass = '';
          this.loginService.firstLogin = 0;
        } else {
          Swal.fire(response.response.message, '', 'error');
        }
      }, (err) => {
        Swal.fire(err.error.message, '', 'error');
      });
    }
    }
}

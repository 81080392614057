<header class="revamp-header main-header">
  <section class="inner-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-2 col-md-3 col-sm-4 col-6">
          <div class="logo-wraapper">
            <span (click)="isMobile = !isMobile; openMobileMenu()"><i class="fas" [ngClass]="{'fa-times':!isMobile, 'fa-bars':isMobile}"></i></span> 
            <img class="logo rounded-circle" src="assets/images/logo.png" title="Restaurant App">
          </div>
        </div>
        <div class="col-xl-10 col-md-9 col-sm-8 col-6">
          <div class="menu-wrapper">
            <ul>
              <li class="dropdown">
                <span class="dropdown-toggle user" data-toggle="dropdown">{{loginService?.userName ? (loginService?.userName | titlecase) : 'Admin'}}</span>
                <div class="dropdown-menu">
                  <!-- <a class="dropdown-item" [routerLink]="['/my-account']">
                    <i class="fa fa-cog" aria-hidden="true"></i>Edit my profile
                  </a> -->
                  <a class="dropdown-item" (click)="logout()" href="javascript:void(0)">
                    <i class="fa fa-power-off" aria-hidden="true"></i> Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</header>
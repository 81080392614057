import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { GlobalSharedService } from '../../shared/service/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public leftPanel;
  public headerLogoUrl = '';
  public userData;
  public isShowHeaderDropDown = false;
  public selectedIndex = 0;
  public isMobile = false;

  constructor(
    public router: Router,
    public renderer: Renderer2,
    public loginService: LoginService,
    public sharedService: GlobalSharedService) { }

  ngOnInit(): void {
    this.loginService.userName = JSON.parse(localStorage.getItem('userData')).name;
  }

  logout() {
    const token = JSON.parse(localStorage.getItem('userData')).api_token;
    this.loginService.signout(token).subscribe(res => {
      localStorage.clear();
      this.router.navigate(['/auth/login']);
    },(err) => {
      localStorage.clear();
      this.router.navigate(['/auth/login']);
    });
  }

  openMobileMenu() {
    this.sharedService.isMobileMenuActive(this.isMobile);
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../service/login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  response: any;
  loginUserData: any;
  @ViewChild('email') public email;
  @ViewChild('password') public password;
  public loginData = { email: '', password: '' };
  public errorMsg = '';
  public errorMsgPass = '';
  public errorMsgGlobal = '';

  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit(): void {

  }

  login(values, type): void {
    this.errorMsg = '';
    this.errorMsgGlobal = '';
    this.errorMsgPass = '';
    if (values.value.email === '') {
      this.errorMsg = 'Please enter the email';
    } else {
      this.errorMsg = '';
    }
    if (values.value.password === '') {
      this.errorMsgPass = 'Please enter the password';
    } else {
      this.errorMsgPass = '';
    }
    if (this.errorMsg !== '' || this.errorMsgPass !== '') {
      return;
    }
    let data;
    if (type === 'email') {
      data = {
        email: values.value.email,
        password: values.value.password
      };
    }
    this.loginService.signIn(data).subscribe(response => {
      if (response.response.code === 200) {
        if (response.response.first === 1) {
          this.loginService.firstLogin = 1;
          this.loginService.token = response.response.api_token;
          if (response.response.roles[0].name === 'level_2' || response.response.roles[0].name === 'level_3') {
            this.router.navigate(['/orders']);
          } else {
            this.router.navigate(['/restaurant']);
          }
          // return;
        }
        this.loginUserData = response.response;
        localStorage.setItem('userData', JSON.stringify(this.loginUserData));
        if (response.response.roles[0].name === 'level_2' || response.response.roles[0].name === 'level_3') {
          this.router.navigate(['/orders']);
        } else {
          this.router.navigate(['/restaurant']);
        }
        this.errorMsg = '';
        this.errorMsgGlobal = '';
        this.errorMsgPass = '';
        localStorage.removeItem('registerDetails');
      } else {
        this.errorMsgGlobal = response.response.message;
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { RestaurantRoutingModule } from './restaurant-routing.module';
import { GlobalDataService } from '../shared/service/data.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RestaurantMainComponent } from './restaurant-main.component';
import { HeaderComponent } from './header/header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [RestaurantMainComponent, HeaderComponent, SideBarComponent],
  imports: [
    RestaurantRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgSelectModule
  ],
  providers: [BsModalService, GlobalDataService],
  exports: [RestaurantRoutingModule]

})
export class RestaurantModule { }

<section class="adminAuth-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class='verification'>
                    <div class='forget-password resetpassInner'>
                        <h3 >Just sit back and relax</h3>
                        <div class='subheadings' style="margin-top: 28px; color: black">
                            <h4>Super admin will connect with you for furthur details</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
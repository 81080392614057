<section class="adminAuth-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class='verification'>
                    <div class='verification-main'>
                        <h3>Verification</h3>
                        <div class='subheadings'>
                            <h4>Enter the code we sent you</h4>
                            <h5>{{mobileNumber}}</h5>
                        </div>
                        <div class="form-group">
                            <ng-otp-input (onInputChange)="onOtpChange($event)" #ngOtpInput [config]="config"></ng-otp-input>
                        </div>
                        <div *ngIf='errorMsg' class="errorClass">
                            <span class='error'>{{errorMsg}}</span>
                        </div>
                        <br>
                        <div class='sub-part'>
                            <h5>Didn't you receive the code?</h5>
                            <span style="font-size: 14px; cursor: pointer;" (click)='resendOtp()'>Resend a new code</span>
                        </div>
                        <div class="form-group authButton-wrapper">
                            <button class='btn btn-lg' (click)='submit(otp)'>Create an account</button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>
<ul class="left-nav">
  <li *ngIf = "showResurant == true">
    <a [routerLink]="['/restaurant']" (click)="changeSection('/restaurant')"
      [ngClass]="{active: dataService?.currentSelection === '/restaurant'}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/dashboard-icon.png'>
      </span>
      <span class="menuName">Restaurant</span>      
    </a>
  </li>
  <li *ngIf = "showResurant == true">
    <a [routerLink]="['/promo-code']" (click)="changeSection('/promo-code')"
      [ngClass]="{active: dataService?.currentSelection === '/promo-code'}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/dashboard-icon.png'>
      </span>
      <span class="menuName">Promo Code</span>      
    </a>
  </li>
  <li *ngIf = "showMenu == true">
    <a [routerLink]="['/menu']" (click)="changeSection('/menu')" [ngClass]="{active: dataService?.currentSelection === '/menu'}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/restaurant-icon.png'>
      </span>
      <span class="menuName">Menu</span>      
    </a>
  </li>
  <li >
    <a *ngIf = "showMeals == true" [routerLink]="['/meals']" (click)="changeSection('/meals')" [ngClass]="{active: (dataService?.currentSelection === '/meals' || dataService?.currentSelection === '/meals/create-meal')}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/meal-icon.png'>
      </span>
      <span class="menuName">Meals</span>      
    </a>
    <a *ngIf = "showMeals == false && createMeal == true" (click)="changeSection('/meals')" [ngClass]="{active: (dataService?.currentSelection === '/meals' || dataService?.currentSelection === '/meals/create-meal')}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/meal-icon.png'>
      </span>
      <span class="menuName">Meals</span>      
    </a>
    <ul *ngIf="dataService?.currentSelection === '/meals' || dataService?.currentSelection ==='/meals/create-meal'">
      <li *ngIf = "createMeal == true">
        <a [routerLink]="['/meals/create-meal']" (click)="changeSection('/meals/create-meal')"
          [ngClass]="{activeChild: dataService?.currentSelection === '/meals/create-meal'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/meal-icon.png'> -->
            <p><small>Creation</small> </p>         
          </span>
          <span class="menuName">Creation</span>
        </a>
      </li>
      <li *ngIf = "showMeals == true">
        <a [routerLink]="['/meals']" (click)="changeSection('/meals')"
        [ngClass]="{activeChild: dataService?.currentSelection === '/meals'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/meal-icon.png'> -->
            <p><small>Meal list</small></p>          
          </span>
          <span class="menuName">Meal list</span>
        </a>
      </li>
    </ul>
  </li>
   <li>
    <a *ngIf = "showItem == true" [routerLink]="['/items']" (click)="changeSection('/items')" [ngClass]="{active: (dataService?.currentSelection === '/items' || dataService?.currentSelection === '/items/create-item')}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/items-icon.png'>
      </span>
      <span class="menuName">Items</span>      
    </a>
    <a *ngIf = "showItem == false && addItem == true"  (click)="changeSection('/items')" [ngClass]="{active: (dataService?.currentSelection === '/items' || dataService?.currentSelection === '/items/create-item')}">
      <span class="menuImg">
        <!-- <img class="img-fluid" src='assets/images/items-icon.png'> -->
        Items
      </span>
      <span class="menuName">Items</span>      
    </a>
    <ul *ngIf="dataService?.currentSelection === '/items' || dataService?.currentSelection ==='/items/create-item'">
      <li *ngIf = "addItem == true">
        <a [routerLink]="['/items/create-item']" (click)="changeSection('/items/create-item')"
          [ngClass]="{activeChild: dataService?.currentSelection === '/items/create-item'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/items-icon.png'> -->
            <p><small>Creation</small></p>
          </span>
          <span class="menuName">Creation</span>
        </a>
      </li>
      <li *ngIf = "showItem == true">
        <a [routerLink]="['/items']" (click)="changeSection('/items')"
          [ngClass]="{activeChild: dataService?.currentSelection === '/items'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/items-icon.png'> -->
            <p><small>Item list</small></p>
          </span>
          <span class="menuName">Item list</span>
        </a>
      </li> 
    </ul>
  </li>
  <li *ngIf = "orderList == true">
    <a [routerLink]="['/orders']" (click)="changeSection('/orders')"
      [ngClass]="{active: dataService?.currentSelection === '/orders'}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/order-icon.png'>
      </span>
      <span class="menuName">Orders</span>      
    </a>
  </li>
 <li *ngIf = "settings == true">
    <a [routerLink]="['/setting/subcategory']" (click)="changeSection('/setting/subcategory')"
      [ngClass]="{active: dataService?.currentSelection === '/setting/subcategory'}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/settings-icon.png'>
      </span>
      <span class="menuName">Settings</span>      
    </a>
    <ul *ngIf="dataService?.currentSelection === '/setting' || dataService?.currentSelection ==='/setting/subcategory' || dataService?.currentSelection ==='/setting/cuisine' ">
      <li>
        <a [routerLink]="['/setting/subcategory']" (click)="changeSection('/setting/subcategory')"
          [ngClass]="{activeChild: dataService?.currentSelection === '/setting/subcategory'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/settings-icon.png'> -->
            <p style=" font-size: 12px"><small>Subcategory</small></p>
            <!-- Subcategory -->
          </span>
          <span class="menuName">Subcategory</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/setting/cuisine']" (click)="changeSection('/setting/cuisine')"
          [ngClass]="{activeChild: dataService?.currentSelection === '/setting/cuisine'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/settings-icon.png'> -->
            <!-- Cuisine type -->
            <p><small>Cuisine type</small></p>
          </span>
          <span class="menuName">Cuisine type</span>
        </a>
      </li>
    </ul>
  </li>
  <li *ngIf="level !== 'employee' && (dashBoardGraph == true || dashBoardSearch == true || dashBoardSellerList == true)">
    <a [routerLink]="['/dashboard']" (click)="changeSection('/dashboard')"
      [ngClass]="{active: dataService?.currentSelection === '/dashboard'}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/dashboard-icon.png'>
      </span>
      <span class="menuName">Dashboard</span>      
    </a>
  </li>
  <li *ngIf="level !== 'employee'">
    <a *ngIf = "showUser == true" [routerLink]="['/users']" (click)="changeSection('/users')" [ngClass]="{active: (dataService?.currentSelection === '/users' || dataService?.currentSelection === '/users/create-user' || dataService?.currentSelection === '/users/profile')}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/user-icon.png'>
      </span>
      <span class="menuName">Users</span>      
    </a>  
    <a *ngIf = "showUser == false && addUser == true" (click)="changeSection('/users')" [ngClass]="{active: (dataService?.currentSelection === '/users' || dataService?.currentSelection === '/users/create-user' || dataService?.currentSelection === '/users/profile')}">
      <span class="menuImg">
        <img class="img-fluid" src='assets/images/user-icon.png'>
      </span>
      <span class="menuName">Users</span>      
    </a>
    <ul *ngIf="dataService?.currentSelection === '/users' || dataService?.currentSelection ==='/users/create-user'  || dataService?.currentSelection ==='/users/profile'">
      <li *ngIf = "showUser == true" >
        <a [routerLink]="['/users']" (click)="changeSection('/users')"
        [ngClass]="{activeChild: dataService?.currentSelection === '/users'}">
        <span class="menuImg">
          <!-- <img class="img-fluid" src='assets/images/user-icon.png'> -->
          <!-- All users -->
          <p><small>All users</small></p>

        </span>
        <span class="menuName">All users</span>
        </a>
      </li>
      <li *ngIf = "addUser == true">
        <a [routerLink]="['/users/create-user']" (click)="changeSection('/users/create-user')"
          [ngClass]="{activeChild: dataService?.currentSelection === '/users/create-user'}">
          <span class="menuImg">
            <!-- <img class="img-fluid" src='assets/images/user-icon.png'> -->
            <!-- Add -->
            <p><small>Add</small></p>

          </span>
          <span class="menuName">Add</span>
        </a>
      </li>
    </ul>
    <ul *ngIf="dataService?.currentSelection === '/users' || dataService?.currentSelection ==='/users/profile' || dataService?.currentSelection ==='/users/create-user'">
      <li>
        <a [routerLink]="['/users/profile']" (click)="changeSection('/users/profile')"
        [ngClass]="{activeChild: dataService?.currentSelection === '/users/profile'}">
        <span class="menuImg">
          <!-- <img class="img-fluid" src='assets/images/user-icon.png'> -->
          <!-- Profile -->
          <p><small>Profile</small></p>

        </span>
        <span class="menuName">Profile</span>
        </a>
      </li>
    </ul>
  </li>
</ul>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthModule } from './main/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RestaurantModule } from './main/restaurant/restaurant.module';
import { LoginService } from './main/service/login.service';
import { HttpClientModule } from '@angular/common/http';
import { LoginGuard } from './main/shared/guards/login-guard';
import { AuthGuard } from './main/shared/guards/auth-guard';
import { GlobalDataService } from './main/shared/service/data.service';
import { GlobalSharedService } from './main/shared/service/shared.service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';


const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./main/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'restaurant',
    loadChildren: () => import('./main/restaurant/restaurant.module').then((m) => m.RestaurantModule),
  },
  {
    path: '**',
    redirectTo: 'auth/login',
  },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    AuthModule,
    RestaurantModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ZXingScannerModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot()
  ],
  providers: [LoginService, LoginGuard, AuthGuard, GlobalDataService, GlobalSharedService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

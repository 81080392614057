<section class="adminAuth-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class='verification'>
                    <div class='forget-password resetpassInner'>
                        <h3 class="head_1">Forgot your password?</h3>
                        <div class='subheadings' style="margin-top: 28px; ">
                            <h4 class="txt_1">Enter your email to receive password reset instruction</h4>
                        </div>
                        <div class="form-group">
                            <span><img src='assets/images/send-icon.png'></span>
                        </div>
                        <form name="forgetPass" #forgetPass="ngForm" autocomplete="off">
                        <div class="form-group">
                            <div class="wrapIcon">
                                <input type='email' name='email' #email="ngModel" [(ngModel)]="loginData.email"  class='form-control' placeholder="Enter email address">
                            </div>
                            <div class="errorClass">
                                <span>{{errorMsgEmail}}</span>
                                <span>{{errorMsgGlobal}}</span>
                            </div>
                        </div>
                        <p class="mt-1 forgot_center">Remember password? <a [routerLink]="'/auth/login'">Login</a> </p>
                        </form>
                        <div class="form-group authButton-wrapper">
                            <button class='btn btn-lg' (click)='sendEmail(forgetPass)'>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>